import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import ModalPopup from '@/shared/components/ModalPopup.vue';
import { validationMixin } from 'vuelidate';
import { serverValidationMixin } from '@/util/validation';
import { maxLength, maxValue, minValue, numeric, required } from 'vuelidate/lib/validators';
import Popover from '@/components/Popover.vue';
import { useGetVenueAndSpacesByVenueIdQuery, useAddCouponMutation, useEditCouponMutation } from '@/generated-types/graphql.types';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import InputHeader from '@/components/InputHeader.vue';
import { cloneDeep, isEqual } from 'lodash';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
export default defineComponent({
    name: 'CreateCouponModal',
    components: {
        InputHeader,
        DatePicker,
        ModalPopup,
        Popover
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        form: {
            couponCode: {
                required,
                maxLength: maxLength(50)
            },
            couponCodeDescription: {
                maxLength: maxLength(500)
            },
            discountRate: {
                required,
                numeric,
                maxValue: maxValue(100),
                minValue: minValue(1)
            },
            expiresAt: {},
            isActive: {},
            isMultiUse: {},
            spaceId: {},
            startsFrom: {
                required
            },
            usageLimit: {
                numeric,
                maxValue: maxValue(999),
                minValue: minValue(1)
            }
        }
    },
    props: {
        venueId: {
            type: Number,
            default: null
        },
        couponData: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref({
            venueId: props.venueId,
            couponCode: '',
            couponCodeDescription: '',
            couponId: null,
            discountRate: null,
            isActive: true,
            isMultiUse: false,
            usageLimit: null,
            spaceId: null,
            startsFrom: null,
            expiresAt: null
        });
        const couponBackup = ref(null);
        const lang = {
            formatLocale: {
                firstDayOfWeek: 1
            }
        };
        const disabledDates = date => {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return date <= yesterday;
        };
        const { result } = useGetVenueAndSpacesByVenueIdQuery({
            venueId: props.venueId
        });
        const { mutate: createCoupon } = useAddCouponMutation({});
        const { mutate: editCoupon } = useEditCouponMutation({});
        const spaces = computed(() => {
            const res = [
                {
                    label: root.$i18n.t('coupon.create_coupon_modal.for_all_spaces_label'),
                    value: null,
                    key: null
                }
            ];
            result.value?.specific_venue.venue_spaces.forEach(space => {
                res.push({
                    label: space.internal_name,
                    value: space.space_id,
                    key: space.space_id
                });
            });
            return res;
        });
        watch(() => props.couponData, data => {
            if (data) {
                form.value.couponCode = data.couponCode;
                form.value.couponCodeDescription = data.description;
                form.value.couponId = data.couponId;
                form.value.discountRate = data.discount;
                form.value.isActive = data.isActive;
                form.value.isMultiUse = data.isMultiUse;
                form.value.usageLimit = data.usageLimit;
                form.value.spaceId = data.spaceId;
                form.value.venueId = data.spaceId ? null : props.venueId;
                form.value.startsFrom = data.startsFrom;
                form.value.expiresAt = data.expiresAt;
                couponBackup.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        const showTimePanel = ref(false);
        const handleOpenChange = () => {
            showTimePanel.value = false;
        };
        const toggleTimePanel = () => {
            showTimePanel.value = !showTimePanel.value;
        };
        watch(() => form.value.spaceId, val => {
            if (val) {
                form.value.venueId = null;
            }
            else {
                form.value.venueId = props.venueId;
            }
        }, { immediate: true });
        const handleSubmit = (createCouponForm) => {
            if (createCouponForm.$invalid)
                return;
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            const { venueId, couponId, couponCode, couponCodeDescription, discountRate, isActive, isMultiUse, usageLimit, spaceId, startsFrom, expiresAt } = form.value;
            (!props.couponData
                ? createCoupon({
                    coupon: {
                        venue_id: venueId,
                        space_id: spaceId,
                        coupon_code: couponCode,
                        coupon_description: couponCodeDescription,
                        starts_from: startsFrom,
                        expires_at: expiresAt,
                        discount_percentage: discountRate,
                        usage_limit: usageLimit,
                        is_active: isActive,
                        is_multi_use: isMultiUse
                    }
                })
                : editCoupon({
                    coupon: {
                        venue_id: venueId,
                        space_id: spaceId,
                        coupon_code: couponCode,
                        coupon_description: couponCodeDescription,
                        coupon_id: couponId,
                        starts_from: startsFrom,
                        expires_at: expiresAt,
                        discount_percentage: discountRate,
                        usage_limit: usageLimit,
                        is_active: isActive,
                        is_multi_use: isMultiUse
                    }
                }))
                .then(() => {
                emit('onSubmit');
                const text = props.couponData ? 'coupon_edited' : 'coupon_created';
                addToast({
                    text: root.$i18n.t(`coupon.toast_messages.${text}`),
                    type: 'success',
                    dismissAfter: 5000
                });
            })
                .catch(() => {
                addToast({
                    text: root.$i18n.t('common.errors.coupon.code_exists'),
                    type: 'danger',
                    dismissAfter: 5000
                });
            });
        };
        const handleScroll = () => {
            const element = document.getElementById('wrapper');
            setTimeout(() => (element.scrollTop = element.scrollHeight), 300);
        };
        return {
            couponBackup,
            form,
            lang,
            showTimePanel,
            spaces,
            disabledDates,
            handleSubmit,
            handleOpenChange,
            handleScroll,
            isEqual,
            toggleTimePanel
        };
    }
});
