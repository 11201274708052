import ModalPopup from '@/shared/components/ModalPopup.vue';
import SpaceCalendarSync from '@/Booking/VendorBookings/components/SpaceCalendarSync.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { parseJwt } from '@/util/utils';
import { useGetSpacesCalendarSyncQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    components: {
        ModalPopup,
        SpaceCalendarSync
    },
    props: {
        venue: {
            type: Number,
            default: null
        },
        code: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            default: ''
        },
        spaceId: {
            type: Number,
            default: null
        },
        codeLoaded: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { result, loading } = useGetSpacesCalendarSyncQuery({ venueId: props.venue, show_all_spaces: true }, {
            fetchPolicy: 'no-cache'
        });
        const spaces = computed(() => {
            return (result?.value?.specific_venue?.venue_spaces?.filter((space) => !!space) || []);
        });
        const getCode = (space) => {
            if (!props.state)
                return null;
            const state = parseJwt(props.state);
            return space.space_id === state.space_id ? props.code : null;
        };
        return {
            loading,
            spaces,
            getCode
        };
    }
});
