export const DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'firstName'
};
export const CITY_DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'cityName'
};
export const FEEDBACK_DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'feedbackOrder'
};
export const PAYOUT_DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'bookingId'
};
export const COUPON_DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'couponCode'
};
