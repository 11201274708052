import { defineComponent } from '@vue/composition-api';
import FormSelect from '@/shared/components/form/FormSelect.vue';
export default defineComponent({
    components: {
        FormSelect
    },
    props: {
        venuesList: {
            type: Array,
            default: () => []
        },
        showedSpacesCount: {
            type: [Number, Boolean],
            default: 0
        },
        value: {
            type: Object,
            default: () => { }
        },
        handleSidebarToggle: {
            type: Function,
            default: () => { }
        },
        events: {
            type: Number,
            default: null
        }
    }
});
