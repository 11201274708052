import { defineComponent } from '@vue/composition-api';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
});
