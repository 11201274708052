import { computed, defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import { DATE_FORMATS } from '@/Calendar/const';
import Calendar from '@/Calendar/we-calendar.vue';
import { unixDate, dateFormat } from '@/util/dates';
export default defineComponent({
    name: 'VenueCalendar',
    components: {
        Calendar
    },
    props: {
        venueFormattedSchedule: {
            type: Object,
            default: () => { }
        },
        size: { type: String, default: 'normal' },
        startDate: {
            type: Date,
            default: null
        },
        spaces: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        return {
            spaceIds: computed(() => props.spaces ? props.spaces.map(el => el.spaceId) : [])
        };
    },
    computed: {
        ...mapGetters({
            currentDay: '$_calendar/currentDay',
            currentWeekNumber: '$_calendar/currentWeekNumber'
        })
    },
    beforeDestroy() {
        this.clearDateInfo();
    },
    methods: {
        ...mapActions({
            setCurrentDay: '$_calendar/setCurrentDay',
            clearDateInfo: '$_calendar/clearDateInfo'
        }),
        dayClick(day) {
            const selectedDay = unixDate(day);
            this.$emit('change', selectedDay);
            this.setCurrentDay(dateFormat(day, DATE_FORMATS.calendarCore));
        }
    }
});
