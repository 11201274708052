import ModalPopup from '@/shared/components/ModalPopup.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useGetVenueAndSpacesByVenueIdQuery } from '@/generated-types/graphql.types';
import SpaceCardWithRadio from '@/Booking/VendorBookings/components/SpaceCardWithRadio.vue';
export default defineComponent({
    components: {
        ModalPopup,
        SpaceCardWithRadio
    },
    props: {
        venueId: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const selectedSpace = ref(undefined);
        const { result, onResult } = useGetVenueAndSpacesByVenueIdQuery({ venueId: props.venueId, show_all_spaces: true }, {
            fetchPolicy: 'no-cache'
        });
        onResult(result => {
            selectedSpace.value =
                result?.data.specific_venue?.venue_spaces[0]?.space_id?.toString();
        });
        const venue = computed(() => {
            const _venue = result?.value?.specific_venue;
            return {
                title: _venue?.venue_name,
                address: _venue?.venue_geo?.address,
                img: _venue?.venue_images[0]?.image_url,
                spaces: _venue?.venue_spaces
            };
        });
        const selectSpace = val => {
            selectedSpace.value = val;
        };
        const applySelected = () => {
            console.log('Selected applied', selectedSpace.value);
            // TODO: redirect to offer page
        };
        return {
            selectedSpace,
            venue,
            applySelected,
            selectSpace
        };
    }
});
