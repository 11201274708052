import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        space: {
            type: Object,
            default: null
        },
        selected: {
            type: Boolean,
            default: null
        }
    }
});
