import { computed, defineComponent, watch, ref, reactive } from '@vue/composition-api';
import { withAuthQueryHook } from '@/util/hooks';
import { useGetVenuesQuery } from '@/generated-types/graphql.types';
import EmptyView from '@/components/EmptyView.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import VendorDashboardProvider from '@/Booking/VendorBookings/VendorDashboardProvider.vue';
import { parseJwt } from '@/util/utils';
import { unixNow } from '@/util/dates';
const getVenuesQuery = withAuthQueryHook(useGetVenuesQuery);
export default defineComponent({
    name: 'VendorDashboard',
    components: {
        EmptyView,
        PageLoadingBar,
        VendorDashboardProvider
    },
    setup(_, context) {
        const { root } = context;
        const spaceVariables = reactive({
            venueId: undefined
        });
        const day = ref(unixNow);
        const state = ref('');
        const code = ref('');
        const isAgendaModalOpened = ref(false);
        // route params and query
        const params = computed({
            get: () => {
                return {
                    venueId: spaceVariables.venueId,
                    day: day.value,
                    state: state.value,
                    code: code.value,
                    isAgendaModalOpened: isAgendaModalOpened.value
                };
            },
            set: ({ venueId, day, state, code, isAgendaModalOpened }) => {
                globalThis.$router.push({
                    query: {
                        day: day.toString(),
                        state,
                        code,
                        isAgendaModalOpened
                    },
                    params: {
                        venueId: venueId?.toString() ?? ''
                    }
                });
            }
        });
        // Updating selected day value
        watch(() => [root.$route.params, root.$route.query], ([{ venueId }, { day: selectedDay, code: codeVal, state: stateCode, isAgendaModalOpened: isAgendaModalOpenedVal }]) => {
            if (venueId) {
                spaceVariables.venueId = +venueId;
            }
            else if (spaceVariables.venueId) {
                const query = {
                    day: day.value,
                    isAgendaModalOpened: isAgendaModalOpened.value
                };
                globalThis.$router.push({
                    params: {
                        venueId: spaceVariables.venueId
                    },
                    query
                });
            }
            if (isAgendaModalOpenedVal) {
                isAgendaModalOpened.value = Boolean(isAgendaModalOpenedVal);
            }
            if (stateCode) {
                state.value = stateCode.toString();
                code.value = codeVal.toString();
            }
            if (selectedDay && !isNaN(Number(selectedDay))) {
                day.value = Number(selectedDay);
            }
        }, { immediate: true });
        // Query to fetch all venues for the user
        const { result: venues, loading, onResult } = getVenuesQuery({}, {
            clientId: 'legacy',
            fetchPolicy: 'no-cache'
        });
        // Updating params with the first venue in a list
        onResult(result => {
            if (!root.$route.params.venueId) {
                const venueId = result?.data?.viewerApiKey?.venuesGet?.[0]?.venueId;
                if (root.$route.query.state) {
                    const parsedState = parseJwt(root.$route.query.state);
                    params.value = { ...params.value, venueId: parsedState.venue_id };
                }
                else {
                    if (venueId) {
                        params.value = { ...params.value, venueId };
                    }
                }
            }
        });
        // List of venues
        const venuesList = computed(() => {
            return (venues.value?.viewerApiKey?.venuesGet?.map(venue => ({
                label: venue?.venueName,
                value: venue?.venueId
            })) ?? []);
        });
        // Updating query params with selected day
        const handleTimeRangeChange = (selectedDay) => {
            params.value = {
                ...params.value,
                day: selectedDay
            };
        };
        // Remove `state` and `isAgendaModalOpened` from query after closing calendar modal window
        const updateQuery = (val) => {
            isAgendaModalOpened.value = val;
            globalThis.$router.replace({
                query: {
                    ...root.$route.query,
                    state: '',
                    isAgendaModalOpened: val
                }
            });
        };
        return {
            loading,
            params,
            spaceVariables,
            venuesList,
            handleTimeRangeChange,
            updateQuery
        };
    }
});
