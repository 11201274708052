import { computed, defineComponent, onMounted, reactive, watch } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
import Calendar from '@/Calendar/we-calendar.vue';
import { formattedSchedule } from '@/util/calendar';
import { dateIsAfter, unixNow, tsFromSlot } from '@/util/dates';
export default defineComponent({
    components: {
        Calendar
    },
    props: {
        value: {
            type: Object,
            default: () => ({ from: unixNow, to: unixNow })
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        isGuestMode: {
            type: Boolean,
            default: false
        },
        workingTime: {
            type: Array,
            default: () => []
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        },
        mode: {
            type: Boolean,
            default: false
        },
        day: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const schedule = computed(() => {
            const wt = Array.from(Array(7).keys());
            const fullWeekSchedule = wt.map((__, index) => ({
                week_day: index + 1,
                open_time: 0,
                close_time: 1440
            }));
            const res = formattedSchedule(props.mode ? fullWeekSchedule : props.workingTime);
            return res.labels ? res : { labels: [] };
        });
        const state = reactive({
            from: null,
            to: null
        });
        onMounted(() => {
            EventBus.$on('updateBookingStartTime', time => {
                state.from = time;
            });
            EventBus.$on('updateBookingEndTime', time => {
                state.to = time;
            });
        });
        watch(() => [state.from, state.to], ([from, to]) => {
            if (to && dateIsAfter(to, from)) {
                const fromAsUnix = tsFromSlot(from, globalThis.$timezone, props.day);
                const toAsUnix = tsFromSlot(to, globalThis.$timezone, props.day);
                emit('input', { from: fromAsUnix, to: toAsUnix });
            }
            else {
                emit('input', {});
            }
        }, { immediate: true });
        return {
            schedule
        };
    }
});
