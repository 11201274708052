import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: String,
            default: ''
        },
        space: {
            type: Object,
            default: () => { }
        }
    }
});
