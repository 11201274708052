import i18n from '@/i18n/i18n-setup';
import ListItem from '@/uikit/ListItem.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { BookingRoutes } from '@/Booking/booking.const';
import { RoleType } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
/** TODO: update according new route logic */
export const links = (hasActiveSpaces, venueId) => new Array().concat(venueId && hasActiveSpaces
    ? {
        title: i18n.t('booking_calendar.label_manage_availability'),
        route: useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}/${venueId}/${BookingRoutes.AVAILABILITY}`),
        icon: 'fa-solid fa-lock-open fa-lg',
        gtmDataName: 'vendor_dashboard_sidebar_manage_availability_button'
    }
    : []);
export default defineComponent({
    components: {
        ListItem
    },
    props: {
        venueId: {
            type: Number,
            default: null
        },
        hasActiveSpaces: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const venueLinks = computed(() => links(props.hasActiveSpaces, props.venueId));
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isOwner = computed(() => user.value?.role_type === RoleType.Owner);
        return { isOwner, venueLinks, BookingRoutes, useGetLocalizedPath };
    }
});
