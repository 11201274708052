import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        data: {
            type: Array,
            default: null
        },
        dots: {
            type: Boolean,
            default: false
        }
    }
});
