import { maxBy, minBy, sortBy } from 'lodash';
import { reFormatTime } from '@/util/utils';
export const formattedSchedule = (workTime) => {
    if (!workTime.length || !workTime)
        return {};
    const minHour = minBy(workTime, 'open_time')?.open_time || 0;
    const maxHour = maxBy(workTime, 'close_time')?.close_time || 0;
    const labels = Array([]);
    let workTimeIteration;
    if (minHour && minHour % 60 !== 0) {
        labels.push({
            label: reFormatTime(minHour),
            value: minHour,
            width: `${((minHour % 60) / 60) * 100}%`
        });
        workTimeIteration = minHour - (minHour % 60) + 60;
    }
    else {
        workTimeIteration = minHour;
    }
    if (maxHour && maxHour % 60 !== 0) {
        labels.push({
            label: reFormatTime(maxHour),
            value: maxHour,
            width: `${((maxHour % 60) / 60) * 100}%`
        });
    }
    while (workTimeIteration <= maxHour) {
        labels.push({
            label: reFormatTime(workTimeIteration),
            value: workTimeIteration,
            width: '100%'
        });
        workTimeIteration += 30;
    }
    return {
        open: minHour,
        close: maxHour,
        labels: sortBy(labels, ['value']).filter(value => Object.keys(value).length !== 0)
    };
};
