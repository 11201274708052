import { computed, defineComponent } from '@vue/composition-api';
import CalendarLegend from '@/components/CalendarLegend.vue';
export default defineComponent({
    components: {
        CalendarLegend
    },
    props: {
        legend: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'month'
        },
        venues: {
            type: Array,
            default: () => []
        },
        selectedVenueId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const preparedData = computed(() => {
            return props.venues.map(venue => {
                return {
                    label: venue.venueName,
                    value: venue.venueId
                };
            });
        });
        return {
            preparedData
        };
    }
});
