import { computed, defineComponent, reactive, watch, onMounted } from '@vue/composition-api';
import { useGetSpacesCalendarDataQuery } from '@/generated-types/graphql.types';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    props: {
        spaceIds: {
            type: Array,
            default: () => []
        },
        months: {
            type: Array,
            default: () => []
        },
        year: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const spacesCalendarDataVariables = reactive({
            months: null,
            spaceIds: null,
            year: globalThis.$moment.year,
            venueId: null
        });
        const spacesCalendarDataOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { result: getSpacesCalendarData, refetch: refetchSpacesCalendarData } = useGetSpacesCalendarDataQuery(spacesCalendarDataVariables, spacesCalendarDataOptions);
        watch(() => props.spaceIds, () => {
            const hasSpaceIds = props.spaceIds.length !== 0;
            if (hasSpaceIds) {
                spacesCalendarDataVariables.spaceIds = props.spaceIds;
                spacesCalendarDataVariables.months = props.months;
                spacesCalendarDataVariables.year = props.year;
                spacesCalendarDataOptions.enabled = hasSpaceIds;
                refetchSpacesCalendarData(spacesCalendarDataVariables);
            }
        }, { immediate: true });
        watch(() => [props.months, props.year], (newData, oldData) => {
            if (newData && oldData) {
                const newYear = newData[newData.length - 1];
                const oldYear = oldData[oldData.length - 1];
                const newMonths = newData[0];
                const oldMonths = oldData[0];
                if (newYear !== oldYear ||
                    JSON.stringify(newMonths) !== JSON.stringify(oldMonths)) {
                    // Re-fetch calendar data when month or year gets changed
                    if (typeof newYear === 'number') {
                        spacesCalendarDataVariables.year = newYear;
                    }
                    if (typeof newMonths === 'object') {
                        spacesCalendarDataVariables.months = newMonths;
                    }
                    refetchSpacesCalendarData(spacesCalendarDataVariables);
                }
            }
        }, { immediate: true });
        onMounted(() => {
            EventBus.$on('spacesAvailabilityUpdated', ({ month, year }) => {
                if (month && year) {
                    spacesCalendarDataVariables.months = [
                        month,
                        month === 12 ? 1 : month + 1
                    ];
                    spacesCalendarDataVariables.year = year;
                }
                refetchSpacesCalendarData(spacesCalendarDataVariables);
            });
        });
        return {
            spacesCalendarData: computed(() => getSpacesCalendarData?.value?.get_spaces_calendar_data)
        };
    }
});
