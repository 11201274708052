import { computed, defineComponent } from '@vue/composition-api';
import { timelineLength, timelineOffset } from '@/Booking/utils/schedule';
import Popover from '@/components/Popover.vue';
export default defineComponent({
    components: {
        Popover
    },
    props: {
        closedSlot: {
            type: Object,
            default: null
        },
        schedule: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const timeline = computed(() => {
            const labelStart = props.closedSlot.closed_labels[0];
            const labelEnd = props.closedSlot.closed_labels[props.closedSlot.closed_labels.length - 1];
            return {
                offset: timelineOffset(props.schedule, {
                    slot_start: labelStart,
                    slot_end: labelEnd
                }),
                width: timelineLength(props.schedule, {
                    slot_start: labelStart,
                    slot_end: labelEnd
                })
            };
        });
        const slotStartTimeAsString = computed(() => {
            return props.closedSlot?.slot_from?.substring(props.closedSlot?.slot_from?.indexOf(' ') + 1);
        });
        const slotEndTimeAsString = computed(() => {
            return props.closedSlot?.slot_to?.substring(props.closedSlot?.slot_to?.indexOf(' ') + 1);
        });
        return {
            slotEndTimeAsString,
            slotStartTimeAsString,
            timeline
        };
    }
});
