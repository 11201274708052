import { computed, ref, defineComponent, watch, reactive } from '@vue/composition-api';
import { useGetSpacesByVenueIdQuery, useVenueQuery, BookingStatus, useGetSpacesCalendarDataQuery, OrderStateType } from '@/generated-types/graphql.types';
import { withAuthQueryHook } from '@/util/hooks';
import Sidebar from '@/components/Sidebar.vue';
import VenueCalendar from '@/Booking/VendorBookings/components/VenueCalendar.vue';
import Links from '@/Booking/VendorBookings/components/Links.vue';
import Header from '@/Booking/VendorBookings/components/Header.vue';
import Spinner from '@/uikit/Spinner.vue';
import Tip from '@/uikit/Tip.vue';
import BookingsSummary from '@/Booking/components/BookingsSummary.vue';
import { BookingDetailsViewMode, BookingTypes } from '@/Booking/booking.const';
import CalendarModule from '@/Calendar/components/Calendar.vue';
import EmptyView from '@/components/EmptyView.vue';
import CalendarSync from '@/Booking/VendorBookings/components/CalendarSync.vue';
import { now, unixTs } from '@/util/dates';
import OfferSpaceSelector from '@/Booking/VendorBookings/components/OfferSpaceSelector.vue';
import { getFormattedSchedule } from '@/Booking/utils/bookings';
import { groupBy } from 'lodash';
const getSpacesByVenueId = withAuthQueryHook(useGetSpacesByVenueIdQuery);
export default defineComponent({
    name: 'VendorDashboardProvider',
    components: {
        VenueCalendar,
        Sidebar,
        Links,
        Header,
        Spinner,
        Tip,
        BookingsSummary,
        CalendarModule,
        EmptyView,
        CalendarSync,
        OfferSpaceSelector
    },
    props: {
        venues: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => { }
        },
        value: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const currentVenueFormattedSchedule = ref(null);
        const month = ref(0);
        const year = ref(now.year());
        const calendarSyncOpen = ref(props.value.isAgendaModalOpened);
        const sidebarOpened = ref(false);
        const query = root.$route.query;
        const codeVal = ref(false);
        const authCodeLoaded = ref(false);
        const offerModal = ref(false);
        const startDate = computed(() => unixTs(props.value.day).toDate());
        const { result: spacesResult, loading: spacesLoading, refetch: refetchSpaces } = getSpacesByVenueId({ token: '', venueId: +props.data.venueId }, {
            clientId: 'legacy',
            errorPolicy: 'all'
        });
        const spaces = computed(() => spacesResult.value?.viewerApiKey?.getSpacesByVenueId);
        const spacesCalendarDataVariables = reactive({
            months: null,
            spaceIds: null,
            year: globalThis.$moment.year,
            venueId: null,
            week: null
        });
        const spacesCalendarDataOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { result: getSpacesCalendarData, refetch: refetchSpacesCalendarData } = useGetSpacesCalendarDataQuery(spacesCalendarDataVariables, spacesCalendarDataOptions);
        const spacesCalendarData = computed(() => {
            return getSpacesCalendarData?.value?.get_spaces_calendar_data;
        });
        const bookings = computed(() => {
            return (spacesCalendarData?.value
                ?.filter(day => day?.space_bookings && day.space_bookings.length > 0)
                .map(day => day?.space_bookings)
                .flat() || []);
        });
        const bookingsSummaryData = computed(() => {
            const bookingsGroupedByStatus = groupBy(bookings.value, booking => booking?.booking_status);
            const bookingsByType = {
                [BookingTypes.REQUESTS]: new Array().concat(bookingsGroupedByStatus.PENDING?.filter(booking => booking?.order_state === OrderStateType.HostApproval ||
                    booking?.order_state === OrderStateType.CustomerApproval) ?? []),
                [BookingTypes.UPCOMING]: new Array().concat(bookingsGroupedByStatus.PAID?.filter(booking => booking.booking_status === BookingStatus.Paid) ?? []),
                [BookingTypes.RESERVATIONS]: new Array().concat(bookingsGroupedByStatus.PENDING?.filter(booking => booking?.order_state === OrderStateType.PaymentPending ||
                    booking?.order_state === OrderStateType.PaymentFailed) ?? []),
                [BookingTypes.ARCHIVED]: new Array().concat(bookingsGroupedByStatus.PASSED ?? [], bookingsGroupedByStatus.EXPIRED ?? [], bookingsGroupedByStatus.CANCELLED ?? [], bookingsGroupedByStatus.DECLINED ?? [])
            };
            return Object.entries(bookingsByType)
                .filter(([key]) => key !== BookingTypes.ARCHIVED)
                .map(([key, value]) => ({
                bookingsType: key,
                bookingsCount: value.length
            }));
        });
        const venueQueryVariables = reactive({
            venueId: +props.data.venueId
        });
        const venueQueryOptions = reactive({
            enabled: false
        });
        const { result: venueResult, refetch: refetchVenueResult } = useVenueQuery(() => venueQueryVariables, () => venueQueryOptions);
        const venue = computed(() => venueResult?.value?.specific_venue);
        const currentVenueWorkingTime = computed(() => venue.value?.working_time || []);
        const calendarSync = () => {
            calendarSyncOpen.value = true;
            emit('openCalendarModal');
        };
        const codeExpired = () => {
            codeVal.value = true;
        };
        if (query.state) {
            calendarSyncOpen.value = true;
        }
        watch(() => props.data.venueId, (newId, oldId) => {
            if (newId && newId !== oldId) {
                // re-fetch venue details
                venueQueryVariables.venueId = newId;
                venueQueryOptions.enabled = true;
                refetchVenueResult(venueQueryVariables);
                // re-fetch venue spaces
                refetchSpaces({ token: '', venueId: newId });
            }
            else {
                venueQueryOptions.enabled = false;
            }
        }, { immediate: true });
        watch(() => props.value, ({ venueId, day }) => {
            if (venueId && day) {
                month.value = unixTs(day).month() + 1; // months in Moment().month() start with 0
                year.value = unixTs(day).year();
                spacesCalendarDataVariables.venueId = venueId;
                spacesCalendarDataVariables.months = [month.value];
                spacesCalendarDataVariables.year = year.value;
                spacesCalendarDataOptions.enabled = !!venueId;
                refetchSpacesCalendarData(spacesCalendarDataVariables);
            }
        }, { immediate: true });
        const importantEventsCount = computed(() => {
            let count = 0;
            bookingsSummaryData.value.forEach(el => {
                count = count + el.bookingsCount;
            });
            return count;
        });
        watch(() => currentVenueWorkingTime.value, () => {
            if (currentVenueWorkingTime.value) {
                currentVenueFormattedSchedule.value = getFormattedSchedule(currentVenueWorkingTime.value);
                currentVenueFormattedSchedule.value.timezone = globalThis.$timezone;
            }
        }, { immediate: true });
        const firstLoadAuthCode = (event) => {
            authCodeLoaded.value = event;
            if (!event)
                codeVal.value = true;
        };
        const modalClose = () => {
            calendarSyncOpen.value = false;
            emit('closeCalendarModal');
        };
        return {
            authCodeLoaded,
            bookings,
            bookingsSummaryData,
            calendarSyncOpen,
            code: computed(() => (!codeVal.value ? query.code : '')),
            currentVenueFormattedSchedule,
            currentVenueWorkingTime,
            importantEventsCount,
            sidebarOpened,
            space: computed(() => query.space),
            spaces,
            spacesLoading,
            startDate,
            state: computed(() => query.state),
            BookingDetailsViewMode,
            calendarSync,
            codeExpired,
            firstLoadAuthCode,
            modalClose,
            offerModal,
            spacesCalendarData
        };
    }
});
