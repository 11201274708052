var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-wrapper"},[_c('div',{staticClass:"space-name"},[_vm._v(" #"+_vm._s(_vm.space.spaceReference)+" "),(_vm.space.internalName)?_c('div',{staticClass:"internal-name"},[_vm._v(" "+_vm._s(_vm.space.internalName)+" ")]):_vm._e()]),_c('div',{staticClass:"space-grid"},[(!_vm.day.close && !_vm.isCompletelyClosed)?[_vm._l((_vm.spaceAvailability),function(closedSlot,index){return [(
            _vm.day.formatedName === closedSlot.slotDateAsString &&
            !closedSlot.is_opened
          )?_c('SpaceClosed',{key:index,attrs:{"closed-slot":closedSlot,"schedule":_vm.schedule}}):_vm._e()]})]:_vm._e(),_vm._l((_vm.spaceBookings),function(booking,index){return [(_vm.day.formatedName === booking.bookingDateAsString)?_c('BookingBadge',{key:("booking-" + (_vm.day.name) + "-" + index),attrs:{"booking":booking,"schedule":_vm.schedule}}):_vm._e()]}),_c('div',{staticClass:"cells"},[(_vm.dayBusinessHours && !_vm.day.close && !_vm.isCompletelyClosed)?_vm._l((_vm.schedule.labels),function(index){return _c('div',{key:index.label,class:[
            'cell',
            {
              closed:
                index.value < _vm.dayBusinessHours.open_time ||
                index.value > _vm.dayBusinessHours.close_time
            }
          ]})}):(_vm.day.close && _vm.openedAvailabilitySlots.length)?_vm._l((_vm.schedule.labels),function(index){return _c('div',{key:index.label,class:['cell', { closed: _vm.openedSlotsMap[index.value] }]})}):_c('div',{staticClass:"closed"})],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }