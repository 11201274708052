export const PROVIDERS = [
    {
        name: 'google',
        icon: 'google-icon.svg'
    },
    {
        name: 'apple',
        icon: 'apple.png'
    },
    {
        name: 'office365',
        icon: 'office.png'
    },
    {
        name: 'exchange',
        icon: 'exchange.jpg'
    },
    {
        name: 'live_connect',
        icon: 'outlook.png'
    }
];
