import { computed, defineComponent, ref } from '@vue/composition-api';
import { dateString } from '@/util/dates';
import { parseJwt } from '@/util/utils';
import { useAuthCalendarMutation, useCreateSpaceCalendarMutation, useSyncSpaceCalendarMutation, useGetExternalCalendarsQuery, useUnlinkSpaceCalendarMutation, useGetCalendarPermissionsMutation } from '@/generated-types/graphql.types';
import { PROVIDERS } from '@/Booking/VendorBookings/const';
export default defineComponent({
    props: {
        space: {
            type: Object,
            default: () => { }
        },
        code: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            default: ''
        },
        venue: {
            type: Number,
            default: null
        },
        codeLoaded: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const { mutate: authCalendar } = useAuthCalendarMutation();
        const { mutate: createCalendar } = useCreateSpaceCalendarMutation();
        const { mutate: syncCalendar } = useSyncSpaceCalendarMutation();
        const { mutate: unlinkCalendar } = useUnlinkSpaceCalendarMutation();
        const { mutate: getPermissions } = useGetCalendarPermissionsMutation();
        const loading = ref(false);
        const isSynchronized = ref(false);
        const calendarName = ref('');
        const provider = ref(undefined);
        const validCode = ref(true);
        const providerSelect = ref(false);
        const lastSyncTs = ref(undefined);
        const mode = ref('sync');
        const calendarSelected = ref(null);
        const providerIcon = ref(undefined);
        const _input = ref({ auth_code: undefined, provider_name: undefined, space_id: undefined });
        const unsubscribeValidation = ref(false);
        const forceUnsubscribe = ref(false);
        const error = ref(false);
        const userCalendars = ref([]);
        const thumbnail = computed(() => `${props.space?.space_images[0].image_url}/tr:w-125`);
        const spaceName = computed(() => props.space?.internal_name || props.space?.space_name);
        const calendar = computed(() => props.space?.space_calendars?.[0]);
        const spaceId = computed(() => props.space.space_id);
        if (calendar.value) {
            isSynchronized.value = calendar.value.is_synchronized;
            calendarName.value = calendar.value.calendar_name;
            provider.value = calendar.value.provider_name;
            lastSyncTs.value = dateString(calendar.value?.ts_last_sync, globalThis.$timezone);
        }
        const selectProvider = () => {
            providerSelect.value = !providerSelect.value;
        };
        const syncSpace = (provider) => {
            authCalendar({
                input: { space_id: spaceId.value, provider_name: provider }
            }, {})
                .then(res => {
                const link = res?.data?.get_calendar_auth_code_link?.auth_code_link;
                if (link)
                    window.location.href = link;
            })
                .catch(() => (error.value = true));
        };
        const unlinkSpace = () => {
            unlinkCalendar({
                space_id: spaceId.value,
                has_rollback_selected: forceUnsubscribe.value
            }, {})
                .then(res => {
                isSynchronized.value = res?.data?.unlink_calendar?.is_synchronized;
                provider.value = res?.data?.unlink_calendar?.provider_name;
                const lastSyncedTimestamp = res?.data?.unlink_calendar?.ts_last_sync;
                if (lastSyncedTimestamp)
                    lastSyncTs.value = dateString(lastSyncedTimestamp, globalThis.$timezone);
                mode.value = 'sync';
            })
                .catch(() => (error.value = true));
        };
        if (props.code && validCode.value) {
            mode.value = 'selector';
            let state = parseJwt(props.state);
            _input.value = {
                provider_name: state.provider_name,
                space_id: spaceId.value
            };
            if (!props.codeLoaded) {
                _input.value.auth_code = props.code;
            }
            const { result, onError } = useGetExternalCalendarsQuery(_input.value, {
                fetchPolicy: 'no-cache'
            });
            onError(() => (error.value = true));
            const calendars = computed(() => {
                return (result?.value?.get_external_calendars_by_provider_name?.map((el) => ({
                    label: el?.calendar_name,
                    value: el?.calendar_id,
                    icon: `/img/icons/${getIcon()}`
                })) || []);
            });
            userCalendars.value = computed(() => calendars.value);
            providerIcon.value = state.provider_name;
            emit('firstLoad', true);
        }
        const cancel = () => {
            mode.value = 'sync';
            emit('firstLoad', false);
        };
        const grantAccess = (calendar) => {
            const __input = {
                space_id: calendar.space_id,
                calendar_id: calendar.calendar_id
            };
            getPermissions(__input)
                .then(res => {
                const link = res?.data?.get_extended_calendar_permissions?.auth_code_link;
                if (link)
                    window.location.href = link;
            })
                .catch(() => (error.value = true));
        };
        const synchronize = () => {
            mode.value = 'sync';
            loading.value = true;
            if (calendarSelected.value !== 1) {
                syncCalendar({
                    ..._input.value,
                    ...{
                        calendar_id: calendarSelected.value
                    }
                }, {})
                    .then(res => grantAccess(res?.data?.sync_space_calendar))
                    .catch(() => (error.value = true));
            }
            else {
                createCalendar({
                    ..._input.value,
                    ...{ state_token: props.state }
                }, {})
                    .then(res => grantAccess(res?.data?.create_space_calendar))
                    .catch(() => (error.value = true));
            }
        };
        const getIcon = () => {
            return PROVIDERS.find(el => el.name === providerIcon.value)?.icon;
        };
        const unsubscribe = () => {
            mode.value = 'unsubscribe';
        };
        const getProviderName = () => {
            return root.$i18n.t(`calendar.provider.${provider.value}`).toString();
        };
        return {
            calendarName,
            calendarSelected,
            error,
            forceUnsubscribe,
            isSynchronized,
            lastSyncTs,
            loading,
            mode,
            provider,
            providerIcon,
            providerSelect,
            spaceName,
            thumbnail,
            unsubscribeValidation,
            userCalendars: computed(() => userCalendars.value.value),
            PROVIDERS,
            cancel,
            getIcon,
            getProviderName,
            selectProvider,
            synchronize,
            syncSpace,
            unlinkSpace,
            unsubscribe
        };
    }
});
