var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"availability-save"},[_c('div',{staticClass:"availability-save-content"},[(!_vm.spaceIds || !_vm.spaceIds.length)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.empty'))+" ")]):(!_vm.value.to || !_vm.value.from)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.no_date'))+" ")]):[_c('div',{staticClass:"availability-save__range"},[_vm._v(" "+_vm._s(_vm.range)+" ")]),_c('div',{staticClass:"availability-save-actions"},[_c('div',{staticClass:"availability-save-actions-buttons"},[_c('WMButton',{attrs:{"data-gtm":"availability_page_close_slots_button","color":"danger","icon-class-name":"fa-solid fa-lock fa-sm"},on:{"click":_vm.onClose}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('booking.availability.save.actions.close')))])]),_c('WMButton',{attrs:{"data-gtm":"availability_page_open_slots_button","icon-class-name":"fa-solid fa-lock-open fa-sm"},on:{"click":function($event){return _vm.onUpdate({ opened: true })}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('booking.availability.save.actions.open')))])])],1)]),_c('WMModal',{on:{"close":function($event){_vm.hasBookings = false}},model:{value:(_vm.confirmationModal.visible),callback:function ($$v) {_vm.$set(_vm.confirmationModal, "visible", $$v)},expression:"confirmationModal.visible"}},[(_vm.hasBookings)?[_c('form',{staticClass:"slot-description-form",staticStyle:{"padding":"0 28px 20px 28px"},on:{"submit":function($event){$event.preventDefault();_vm.onUpdate({
                opened: !!_vm.confirmationModal.opened,
                reason: _vm.$v.form.reason.$model,
                form: _vm.$v.form
              });
              _vm.hasBookings = false;}}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.heading'))+" ")]),_c('p',{staticClass:"availability-save-confirmation__text"},[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.hasBookings', { date: _vm.range }))+" ")]),(!_vm.confirmationModal.opened)?_c('WMTextArea',{staticClass:"slot-description-form__textarea",attrs:{"data-gtm":"availability_slot_closing_reason_input","placeholder":_vm.$t(
                  'booking.availability.form.slot_closing_reason_placeholder'
                ).toString()},model:{value:(_vm.$v.form.reason.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.reason, "$model", $$v)},expression:"$v.form.reason.$model"}}):_vm._e(),_c('div',{staticClass:"availability-save-confirmation__actions"},[_c('WMButton',{attrs:{"small":"","color":"danger","icon-class-name":"fa-solid fa-check fa-xs"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t( 'booking.availability.save.confirmation.actions.approve' ))+" ")])]),_c('WMButton',{attrs:{"small":"","color":"accent","icon-class-name":"fa-solid fa-arrow-left fa-xs"},on:{"click":function($event){_vm.confirmationModal.visible = false;
                  _vm.hasBookings = false;}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.actions.back'))+" ")])])],1)],1)]:[_c('form',{staticClass:"slot-description-form",staticStyle:{"padding":"0 28px 20px 28px"},on:{"submit":function($event){$event.preventDefault();return _vm.onUpdate({ reason: _vm.$v.form.reason.$model, form: _vm.$v.form })}}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.heading'))+" ")]),(_vm.confirmationModal.opened)?_c('p',{staticClass:"availability-save-confirmation__text"},[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.text', { date: _vm.range }))+" ")]):_c('p',{staticClass:"availability-save-confirmation__text"},[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.text-close', { date: _vm.range }))+" ")]),(!_vm.confirmationModal.opened)?_c('WMTextArea',{staticClass:"slot-description-form__textarea",attrs:{"data-gtm":"availability_slot_closing_reason_input","placeholder":_vm.$t(
                  'booking.availability.form.slot_closing_reason_placeholder'
                ).toString()},model:{value:(_vm.$v.form.reason.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.reason, "$model", $$v)},expression:"$v.form.reason.$model"}}):_vm._e(),_c('div',{staticClass:"availability-save-confirmation__actions"},[_c('WMButton',{attrs:{"small":"","color":"danger","icon-class-name":"fa-solid fa-check fa-xs"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t( 'booking.availability.save.confirmation.actions.approve' ))+" ")])]),_c('WMButton',{attrs:{"small":"","color":"accent","icon-class-name":"fa-solid fa-arrow-left fa-xs"},on:{"click":function($event){_vm.confirmationModal.visible = false}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t('booking.availability.save.confirmation.actions.back'))+" ")])])],1)],1)]],2)]],2),(_vm.spaces && _vm.spaces.length > 1 && _vm.value.from && _vm.value.to)?_c('div',{staticClass:"availability-save-notification"},[_c('i18n',{staticClass:"availability-save-notification__label",attrs:{"path":"booking.availability.save.actions.spaces","tag":"span"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fa-solid fa-triangle-exclamation"})]},proxy:true},{key:"spaces",fn:function(){return [_c('Popover',{attrs:{"placement":"bottom"}},[_c('SpacePopover',{attrs:{"slot":"content","spaces":_vm.spaces},slot:"content"}),_c('span',{staticClass:"availability-save-notification__counter"},[_vm._v(_vm._s(_vm.spaces.length))])],1)]},proxy:true}],null,false,1251412279)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }