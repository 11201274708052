import { computed, defineComponent } from '@vue/composition-api';
import DateRangePicker from 'vue2-daterange-picker';
import { formatDate, tsFromSlot, dateFormat, getWeekDay, isSameDay } from '@/util/dates';
import { unixNow, yesterday, dateTzFormat, dateTzUnix, dateFromStart } from '@/util/dates';
export default defineComponent({
    components: {
        DateRangePicker
    },
    props: {
        value: {
            type: Object,
            default: () => ({ from: unixNow, to: unixNow })
        },
        workingTime: {
            type: Array,
            default: () => []
        },
        spacedIds: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const date = computed(() => {
            if (!props.value.from) {
                return {
                    startDate: null,
                    endDate: null
                };
            }
            return {};
        });
        const minDate = yesterday;
        const weekDays = computed(() => globalThis.$moment.weekdaysMin());
        const monthNames = computed(() => globalThis.$moment.months());
        const generateClasses = (classes, date) => formatDate(classes, date, props);
        const dayTimezone = (date, mask) => dateTzFormat(date, globalThis.$timezone, mask);
        const changeMonth = (date) => emit('month', dateTzUnix(date, globalThis.$timezone));
        const changeDates = (dates) => {
            if (isSameDay(dates.startDate, dates.endDate)) {
                const wd = getWeekDay(dates.startDate);
                const wt = props.workingTime.find(el => el.week_day === wd);
                if (wt) {
                    const from = tsFromSlot(wt.open_time, globalThis.$timezone, dateFormat(dates.startDate, 'YYYY-MM-DD'));
                    const to = tsFromSlot(wt.close_time, globalThis.$timezone, dateFormat(dates.endDate, 'YYYY-MM-DD'));
                    emit('input', { from, to });
                }
            }
            else {
                emit('input', {
                    from: dateFromStart(dates.startDate, globalThis.$timezone),
                    to: dateFromStart(dates.endDate, globalThis.$timezone, 1)
                });
            }
        };
        return {
            date,
            minDate,
            monthNames,
            weekDays,
            changeDates,
            changeMonth,
            dayTimezone,
            generateClasses
        };
    }
});
