import SpaceWrapper from '@/Calendar/components/SpaceWrapper.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: {
        SpaceWrapper
    },
    props: {
        day: {
            type: Object,
            required: true
        },
        spaces: {
            type: Array,
            required: true
        },
        schedule: {
            type: Object,
            default: () => ({})
        },
        venueBusinessHours: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    }
});
