import BookingBadge from '@/Calendar/components/BookingBadge.vue';
import SpaceClosed from '@/Calendar/components/SpaceClosed.vue';
import { BookingStatus } from '@/generated-types/graphql.types';
import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: {
        BookingBadge,
        SpaceClosed
    },
    props: {
        space: {
            type: Object,
            required: true
        },
        day: {
            type: Object,
            required: true
        },
        schedule: {
            type: Object,
            default: () => ({})
        },
        venueBusinessHours: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const isCompletelyClosed = computed(() => {
            const day = props.spacesCalendarData.find(data => data.day === props.day.formatedName);
            return day?.is_day_fully_closed || false;
        });
        const dayBusinessHours = computed(() => props.venueBusinessHours.find(dayBusinessHours => dayBusinessHours.week_day === props.day.weekDay));
        const spaceAvailability = computed(() => {
            const spaceAvailabilitySlots = props.spacesCalendarData
                .filter(day => day?.space_availability && day?.space_availability?.length > 0)
                .map(day => day.space_availability);
            return spaceAvailabilitySlots
                .flat()
                .filter(slot => slot?.space_id === props.space.spaceId)
                .map(slot => {
                return {
                    ...slot,
                    slotDateAsString: slot?.slot_from.substring(0, slot?.slot_from.indexOf(' '))
                };
            });
        });
        // When the venue is closed (not business day) but the vendor opened manually a slot(s) this day
        const openedAvailabilitySlots = computed(() => spaceAvailability.value.filter(slot => slot?.is_opened));
        const openedSlotsMap = computed(() => Object.assign({}, ...props.schedule?.labels?.map(label => ({
            [label.value]: !openedAvailabilitySlots.value.some(slot => slot?.closed_labels?.includes(label.value) &&
                // Exclude upper boundary of the opened slot
                label.value !==
                    slot.closed_labels[slot.closed_labels.length - 1] &&
                slot.slotDateAsString === props.day.formatedName)
        }))));
        const spaceBookings = computed(() => {
            const bookings = props.spacesCalendarData
                .filter(day => day?.space_bookings && day?.space_bookings?.length > 0)
                .map(day => day.space_bookings);
            return bookings
                .flat()
                .filter(booking => booking?.space_id === props.space.spaceId &&
                booking?.booking_status &&
                [
                    BookingStatus.Paid,
                    BookingStatus.PaymentProcessing,
                    BookingStatus.Pending
                ].includes(booking.booking_status))
                .map(booking => {
                return {
                    ...booking,
                    bookingDateAsString: booking?.slot_start.substring(0, booking?.slot_start.indexOf(' '))
                };
            });
        });
        return {
            dayBusinessHours,
            isCompletelyClosed,
            openedAvailabilitySlots,
            openedSlotsMap,
            spaceAvailability,
            spaceBookings
        };
    }
});
