import { defineComponent, ref, computed } from '@vue/composition-api';
export default defineComponent({
    name: 'DataTable',
    props: {
        headerFields: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        sortField: {
            type: String,
            default: ''
        },
        sort: {
            type: String,
            default: ''
        },
        notFoundMsg: {
            type: String,
            default: ''
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        css: {
            type: Object,
            default: () => ({
                table: '',
                thead: 'thead',
                theadTr: 'thead-tr',
                theadTh: 'thead-th',
                tbody: 'tbody',
                tbodyTr: 'tbody-tr',
                tbodyTrSpinner: 'tbody-tr-spinner',
                tbodyTd: 'tbody-td',
                tbodyTdSpinner: 'tbody-td-spinner',
                tfoot: 'tfoot',
                tfootTd: 'tfoot-td',
                tfootTr: 'tfoot-tr',
                footer: 'footer',
                thWrapper: 'th-wrapper',
                thWrapperCheckboxes: 'th-wrapper-checkboxes',
                arrowsWrapper: 'arrows-wrapper',
                arrowUp: 'arrow-up',
                arrowDown: 'arrow-down',
                checkboxHeader: 'checkbox-header',
                checkbox: 'checkbox',
                notFoundTr: 'not-found-tr',
                notFoundTd: 'not-found-td'
            })
        },
        tableHeight: {
            type: String,
            default: ''
        },
        defaultColumnWidth: {
            type: String,
            default: '150px'
        },
        onlyShowOrderedArrow: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const sortedField = ref(props.sortField);
        const sortedDir = ref(props.sort);
        const notFoundMessage = ref(props.notFoundMsg);
        const loading = ref(props.isLoading);
        const tbodyStyle = computed(() => {
            if (props.tableHeight) {
                return {
                    height: props.tableHeight,
                    display: 'block',
                    overflowX: 'auto'
                };
            }
            return null;
        });
        const theadStyle = computed(() => props.tableHeight ? { display: 'block' } : null);
        const headers = computed(() => {
            if (props.headerFields &&
                props.headerFields.constructor === Array &&
                props.headerFields.length) {
                return Object.keys(props.headerFields).map(key => {
                    const field = props.headerFields[key];
                    if (typeof field === 'string') {
                        return { label: field, name: field };
                    }
                    return field;
                });
            }
            return [];
        });
        const arrowsWrapper = (field, className) => {
            if (sortedField.value === field && sortedDir.value) {
                return `${className} centralized`;
            }
            return className;
        };
        const updateData = () => {
            const params = {
                sortField: sortedField.value,
                sort: sortedDir.value
            };
            context.emit('on-update', params);
        };
        const isFieldSortable = (field) => {
            const foundHeader = props.headerFields.find(item => item.name === field);
            return foundHeader && foundHeader.sortable;
        };
        const orderBy = (field) => {
            if (isFieldSortable(field)) {
                if (sortedField.value === field) {
                    sortedDir.value = sortedDir.value === 'asc' ? 'desc' : 'asc';
                }
                else {
                    sortedDir.value = 'desc';
                    sortedField.value = field;
                }
                updateData();
            }
        };
        const headerItemClass = (item, className = []) => {
            const classes = className.join(' ');
            return item && item.sortable ? classes : `${classes} no-sortable`;
        };
        const isFieldSpecial = (field) => field.indexOf('__') > -1;
        const getColumnWidth = (item) => {
            if (props.tableHeight) {
                if (item.name === '__slot:checkboxes') {
                    return { width: '50px' };
                }
                return { width: item.width || props.defaultColumnWidth };
            }
        };
        const customElementName = ({ customElement, name }) => typeof customElement === 'string' ? customElement : name;
        const showOrderArrow = (item, sortDir) => {
            if (props.onlyShowOrderedArrow) {
                return sortedField.value === item.name && sortedDir.value !== sortDir;
            }
            return (sortedField.value !== item.name ||
                (sortedField.value === item.name && sortedDir.value === sortDir));
        };
        const hasSlots = computed(() => {
            return (context.slots.pagination !== undefined ||
                context.slots.ItemsPerPage !== undefined);
        });
        return {
            hasSlots,
            headers,
            loading,
            notFoundMessage,
            sortedField,
            sortedDir,
            tbodyStyle,
            theadStyle,
            arrowsWrapper,
            customElementName,
            getColumnWidth,
            headerItemClass,
            isFieldSpecial,
            orderBy,
            showOrderArrow,
            updateData
        };
    }
});
