import BackButton from '@/components/BackButton.vue';
import { computed, defineComponent, ref, watch, onBeforeMount } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useGetAllVenueCouponsQuery } from '@/generated-types/graphql.types';
import CreateCouponModal from '@/Coupon/components/CreateCouponModal.vue';
import DataTable from '@/components/DataTable.vue';
import { orderBy } from 'lodash';
import { COUPON_DATA_TABLE_SETTINGS } from '@/Admin/const';
import i18n from '@/i18n/i18n-setup';
import DeleteCouponModal from '@/Coupon/components/DeleteCouponModal.vue';
import { dateDSTCheck } from '@/util/dates';
import { BookingRoutes } from '@/Booking/booking.const';
const tableLabels = [
    {
        name: 'couponCode',
        label: i18n.t('coupon.table_column_names.coupon_code'),
        sortable: true,
        customElement: 'couponCodeSlot'
    },
    {
        name: 'description',
        label: i18n.t('coupon.table_column_names.description'),
        sortable: true,
        customElement: 'descriptionSlot'
    },
    {
        name: 'startsFrom',
        label: i18n.t('coupon.table_column_names.valid_from'),
        sortable: true,
        customElement: 'startsFromSlot'
    },
    {
        name: 'expiresAt',
        label: i18n.t('coupon.table_column_names.valid_until'),
        sortable: true,
        customElement: 'expiresAtSlot'
    },
    {
        name: 'discount',
        label: i18n.t('coupon.table_column_names.discount'),
        sortable: true,
        customElement: 'discountSlot'
    },
    {
        name: 'usageLimit',
        label: i18n.t('coupon.table_column_names.usage_limit'),
        sortable: true,
        customElement: 'usageLimitSlot'
    },
    {
        name: 'timesApplied',
        label: i18n.t('coupon.table_column_names.times_applied'),
        sortable: true,
        customElement: 'timesAppliedSlot'
    },
    {
        name: 'timesUsed',
        label: i18n.t('coupon.table_column_names.times_used'),
        sortable: true,
        customElement: 'timesUsedSlot'
    },
    {
        name: 'isActive',
        label: i18n.t('coupon.table_column_names.active'),
        sortable: true,
        customElement: 'isActiveSlot'
    },
    {
        name: 'created',
        label: i18n.t('coupon.table_column_names.created_at'),
        sortable: true,
        customElement: 'createdSlot'
    },
    {
        name: 'action',
        label: i18n.t('coupon.table_column_names.action'),
        sortable: false,
        customElement: 'actionSlot'
    }
];
export default defineComponent({
    name: 'CouponManagement',
    components: {
        DeleteCouponModal,
        DataTable,
        BackButton,
        CreateCouponModal
    },
    setup(_, context) {
        const { root } = context;
        const venueId = +root.$route.params.venueId;
        const createCouponModalStatus = ref(false);
        const deleteCouponId = ref(null);
        const dataTable = ref(null);
        const { result, refetch } = useGetAllVenueCouponsQuery({ venueId });
        const coupons = computed(() => result?.value?.get_all_venue_coupons);
        const coupon = ref(null);
        watch(() => coupons.value, () => {
            if (coupons.value) {
                dataTable.value = coupons.value?.reduce((acc, coupon) => {
                    const data = {
                        couponId: coupon?.coupon_id,
                        couponCode: coupon?.coupon_code,
                        description: coupon?.coupon_description,
                        startsFrom: dateDSTCheck(coupon?.starts_from, globalThis.$timezone, 'YYYY-MM-DD HH:mm'),
                        expiresAt: coupon?.expires_at
                            ? dateDSTCheck(coupon?.expires_at, globalThis.$timezone, 'YYYY-MM-DD HH:mm')
                            : null,
                        discount: coupon?.discount_percentage,
                        spaceId: coupon?.space ? coupon?.space.space_id : null,
                        usageLimit: coupon?.usage_limit,
                        timesApplied: coupon?.times_applied,
                        timesUsed: coupon?.times_used,
                        isActive: coupon?.is_active,
                        created: dateDSTCheck(coupon?.ts_created, globalThis.$timezone, 'YYYY-MM-DD HH:mm'),
                        isMultiUse: coupon?.is_multi_use
                    };
                    if (!coupon.space) {
                        acc[0].push(data);
                    }
                    else if (!Object.prototype.hasOwnProperty.call(acc, coupon.space.internal_name)) {
                        acc[coupon.space.internal_name] = [data];
                    }
                    else {
                        acc[coupon.space.internal_name].push(data);
                    }
                    return acc;
                }, { 0: [] });
            }
        }, { immediate: true });
        const handleUpdateCoupon = (couponId, spaceName) => {
            coupon.value = dataTable.value[spaceName].find(coupon => coupon.couponId === couponId);
            createCouponModalStatus.value = true;
        };
        const handleCloseCouponModal = () => {
            createCouponModalStatus.value = false;
            coupon.value = null;
        };
        const handleSubmitCouponModal = () => {
            refetch();
            handleCloseCouponModal();
        };
        const updateSort = ({ sortField, sort }, spaceName) => {
            dataTable.value[spaceName] = orderBy(dataTable.value[spaceName], [sortField], [sort]);
        };
        const refetchCoupons = () => {
            refetch();
        };
        onBeforeMount(() => {
            if (globalThis.$i18n.locale === 'nl') {
                import('vue2-datepicker/locale/nl');
            }
        });
        return {
            createCouponModalStatus,
            coupon,
            dataTable,
            deleteCouponId,
            tableLabels,
            tableSettings: computed(() => COUPON_DATA_TABLE_SETTINGS),
            venueId,
            BookingRoutes,
            handleCloseCouponModal,
            handleSubmitCouponModal,
            handleUpdateCoupon,
            refetchCoupons,
            updateSort,
            useGetLocalizedPath
        };
    }
});
