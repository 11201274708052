const dayLength = (schedule) => {
    // The timeline length in minutes
    // (Note! Upper timeline bound is extended with 1 additional hour for a comfortable view)
    return schedule.close - schedule.open + 60;
};
export const timelineOffset = (schedule, slots) => {
    // Timeline length in minutes
    const timelineLength = dayLength(schedule);
    // How far the booking lower bound is from the timeline lower bound (in minutes)
    const bookingOffsetMinutes = slots.slot_start - schedule.open;
    if (bookingOffsetMinutes < 0) {
        // If the booking lower bound is less than the timeline lower bound (not really possible, edge case) - no offset
        return '0%';
    }
    return `${bookingOffsetMinutes / (timelineLength / 100)}%`;
};
export const timelineLength = (schedule, slots) => {
    // Timeline length in minutes
    const timelineLength = dayLength(schedule);
    if (slots.slot_end - slots.slot_start > timelineLength) {
        // If the booking slots are bigger than the venue timeline (not really possible, edge case) - fill the whole row
        return '100%';
    }
    // Booking badge start is the start of the booking but not less than the lower timeline bound
    const slotFrom = slots.slot_start < schedule.open ? schedule.open : slots.slot_start;
    // Booking badge end is the end of the booking but not bigger than the upper timeline bound
    // (Note! Upper timeline bound is extended with 1 additional hour for a comfortable view)
    const slotTo = slots.slot_end > schedule.close ? schedule.close + 60 : slots.slot_end;
    return `${(100 * (slotTo - slotFrom)) / timelineLength}%`;
};
