import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            required: true
        },
        status: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const slotStartTimeAsString = computed(() => {
            return props.booking?.slot_start?.substring(props.booking?.slot_start?.indexOf(' ') + 1);
        });
        const slotEndTimeAsString = computed(() => {
            return props.booking?.slot_end?.substring(props.booking?.slot_end?.indexOf(' ') + 1);
        });
        return {
            slotEndTimeAsString,
            slotStartTimeAsString
        };
    }
});
