import ModalPopup from '@/shared/components/ModalPopup.vue';
import { defineComponent } from '@vue/composition-api';
import { useDeactivateCouponMutation } from '@/generated-types/graphql.types';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
export default defineComponent({
    name: 'DeleteCouponModal',
    components: {
        ModalPopup
    },
    props: {
        couponId: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit, root }) {
        const { mutate } = useDeactivateCouponMutation({});
        const handleDeleteCoupon = () => {
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            mutate({ couponId: props.couponId }).then(() => {
                addToast({
                    text: root.$i18n.t('coupon.toast_messages.coupon_deactivated'),
                    type: 'success',
                    dismissAfter: 5000
                });
                emit('onDelete');
                emit('close');
            });
        };
        return {
            handleDeleteCoupon
        };
    }
});
