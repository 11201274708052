import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import { createPopper } from '@popperjs/core';
import BookingPopup from '@/Calendar/components/BookingPopup.vue';
import { getBookingBadgeStatus, bookingBadgeMeta, BOOKING_BADGE_STATUSES } from '@/Booking/utils/bookings';
import { BookingRoutes } from '@/Booking/booking.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { stringToSlot } from '@/util/dates';
import { timelineLength, timelineOffset } from '@/Booking/utils/schedule';
export default defineComponent({
    components: { BookingPopup },
    props: {
        schedule: {
            type: Object,
            default: null
        },
        booking: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const bookingBadge = ref(undefined);
        const bookingPopup = ref(undefined);
        const bookingBadgeStatus = computed(() => getBookingBadgeStatus(props.booking.booking_status, props.booking.order_state));
        const icon = computed(() => bookingBadgeMeta[bookingBadgeStatus.value]);
        const statusLabel = computed(() => [
            BOOKING_BADGE_STATUSES.RESERVED_BY_CUSTOMER,
            BOOKING_BADGE_STATUSES.RESERVED_BY_OFFER
        ].includes(bookingBadgeStatus.value)
            ? BOOKING_BADGE_STATUSES.RESERVED.toLowerCase()
            : bookingBadgeStatus.value.toLowerCase());
        onMounted(() => {
            createPopper(bookingBadge.value, bookingPopup.value.$el, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 20]
                        }
                    }
                ]
            });
        });
        const navigateToBooking = () => globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${props.booking.booking_id}`));
        const timeline = computed(() => {
            const slot_start = stringToSlot(props.booking.slot_start);
            const slot_end = stringToSlot(props.booking.slot_end);
            return {
                offset: timelineOffset(props.schedule, { slot_start, slot_end }),
                width: timelineLength(props.schedule, { slot_start, slot_end })
            };
        });
        return {
            bookingPopup,
            bookingBadge,
            icon,
            statusLabel,
            timeline,
            navigateToBooking
        };
    }
});
