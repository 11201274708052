import map from 'lodash/map';
import find from 'lodash/find';
import TimeLine from '@/Calendar/components/TimeLine.vue';
import DayView from '@/Calendar/components/DayViewIndex.vue';
import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: {
        TimeLine,
        DayView
    },
    props: {
        schedule: {
            type: Object,
            default: () => ({})
        },
        workTime: {
            type: Array,
            default: () => []
        },
        spaces: { type: Array, default: () => [] },
        spacesAvailability: {
            type: Array,
            default: () => []
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const daysWithWorkTime = computed(() => {
            return map(globalThis.$store.getters['$_calendar/days'], day => {
                return find(props.workTime, workingDay => workingDay.week_day === day.weekDay)
                    ? { ...day, close: false }
                    : { ...day, close: true };
            });
        });
        return {
            daysWithWorkTime
        };
    }
});
