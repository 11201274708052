import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        labels: {
            type: Array,
            default: () => []
        },
        margin: {
            type: Object,
            default: () => ({
                left: 44,
                right: 0
            })
        }
    },
    setup(props, context) {
        const { root } = context;
        const modifiedLabels = computed(() => {
            if (root.$mq.desktop) {
                // Return only labels for the whole hours (09:00, 10:00, ...)
                return props.labels.filter(label => label.value % 60 === 0);
            }
            else {
                // Return only labels for odd whole hours (10:00, 12:00, 14:00, ...)
                return props.labels.filter(label => label.value % 120 === 0);
            }
        });
        return {
            modifiedLabels
        };
    }
});
