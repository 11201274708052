import { computed, defineComponent } from '@vue/composition-api';
var CalendarMode;
(function (CalendarMode) {
    CalendarMode["WEEK"] = "week";
    CalendarMode["MONTH"] = "month";
})(CalendarMode || (CalendarMode = {}));
const LIST = {
    month: ['today', 'opened', 'selected', 'month-closed', 'partially', 'past'],
    week: ['opened', 'selected', 'week-closed', 'past']
};
export default defineComponent({
    props: {
        mode: {
            type: String,
            default: 'month'
        }
    },
    setup(props) {
        return {
            items: computed(() => LIST[props.mode])
        };
    }
});
