import { render, staticRenderFns } from "./AvailabilityCalendar.vue?vue&type=template&id=8c070584&scoped=true&"
import script from "./AvailabilityCalendar.vue?vue&type=script&lang=ts&"
export * from "./AvailabilityCalendar.vue?vue&type=script&lang=ts&"
import style0 from "./AvailabilityCalendar.vue?vue&type=style&index=0&id=8c070584&prod&lang=scss&scoped=true&"
import style1 from "vue2-daterange-picker/dist/vue2-daterange-picker.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c070584",
  null
  
)

export default component.exports