var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-line"},[_c('div',{staticClass:"hours",style:({
      marginLeft: ((_vm.margin.left) + "px"),
      marginRight: ((_vm.margin.right) + "px")
    })},_vm._l((_vm.modifiedLabels),function(label,key){return _c('div',{key:key,staticClass:"hour",style:({
        width: label.width
      })},[(
          _vm.$root.$mq.desktop ? label.value % 60 === 0 : label.value % 120 === 0
        )?_c('span',[_vm._v(_vm._s(label.label))]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }